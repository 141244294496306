import { CssBaseline } from '@mui/material'
import AppProvider from 'AppProvider'
import React from 'react'
import ReactDOM from 'react-dom'
import { ToastContainer, Zoom } from 'react-toastify'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'react-toastify/dist/ReactToastify.css'

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <CssBaseline />
      <ToastContainer transition={Zoom} hideProgressBar position='top-center' />
      <App />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
