import { css, styled } from '@mui/material/styles'

export const Container = styled('div')`
  display: flex;
`

export const MiddleSidebar = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  width: 100%;
`

export const SidebarList = styled('ul')`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
`

interface HiddenSidebarProps {
  isActive?: boolean
}

export const HiddenSidebar = styled('div')<HiddenSidebarProps>`
  margin-left: 1.6rem;
  white-space: nowrap;

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: 600;
      color: #9d9cb5;
    `}
`

interface SidebarListItemProps {
  isActive?: boolean
}

export const SidebarListItem = styled('li')<SidebarListItemProps>`
  position: relative;
  width: 100%;
  fill: rgb(144, 144, 144);
  padding: 6px;

  ${({ isActive }) =>
    isActive &&
    css`
      border-left: 4px solid #9d9cb5;
    `}

  &:hover {
    background-color: rgb(244, 244, 244);
    fill: #9d9cb5;
    font-weight: 700;
  }
`

interface CenterIconProps {
  isActive?: boolean
}

export const CenterIcon = styled('div')<CenterIconProps>`
  display: flex;
  align-items: center;

  ${({ isActive }) =>
    isActive &&
    css`
      margin-left: -4px;
    `}
`

export const SidebarStyled = styled('aside')`
  flex-shrink: 0;
  overflow: hidden;
  width: 75px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  align-items: center;
  justify-content: stretch;
  transition: width 200ms ease-in-out;
  position: sticky;
  left: 0;
  top: 100px;

  &:hover {
    width: 250px;
  }
`
