import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab'
import { format } from 'date-fns'

interface EditHistoricProps {
  data?: any[]
  position?: 'left' | 'right' | 'alternate'
  selectedItemId?: string
  onItemClick?: (item: any) => void
}

function EditHistoric({
  data,
  position = 'right',
  selectedItemId,
  onItemClick = () => {},
}: EditHistoricProps) {
  return (
    <Timeline position={position} style={{ display: 'flex' }}>
      {data?.map((item, index) => (
        <TimelineItem
          key={item.id}
          onClick={() => onItemClick(item)}
          style={{
            cursor: 'pointer',
          }}
        >
          <TimelineContent>
            <p
              title={item.example}
              style={{
                margin: 0,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: 200,
                fontWeight: item.id === selectedItemId ? 'bold' : '',
              }}
            >
              {item.example}
            </p>
          </TimelineContent>
          <TimelineSeparator>
            <TimelineDot
              color={item.id === selectedItemId ? 'primary' : 'grey'}
            />
            {data.length !== index + 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineOppositeContent>
            <p
              style={{
                margin: 0,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: 135,
                fontWeight: item.id === selectedItemId ? 'bold' : '',
              }}
            >
              {format(new Date(item.createdAt), 'dd/MM/yyyy HH:mm')}
            </p>
          </TimelineOppositeContent>
        </TimelineItem>
      ))}
    </Timeline>
  )
}

export default EditHistoric
