/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { InputField } from 'components/inputs'
import { getClinicByUserId, getUserById } from 'services'
import { Container } from 'styles/container'
import useFormUserDetail from './user-detail-form'
import { ClinicProps, UserProps } from 'types/users-types'
import { CLINIC } from 'helpers/constants'
import { Box, IconButton } from '@mui/material'
import { ChevronLeft } from '@mui/icons-material'
import { Loading } from 'components'

function UserDetail() {
  const [data, setData] = useState<UserProps>()
  const [clinicData, setClinicData] = useState<ClinicProps>()
  const [isLoading, setIsLoading] = useState(true)

  const { control } = useFormUserDetail(data)
  const navigate = useNavigate()

  const {
    state: { id },
  } = useLocation()

  const getClinicData = async () => {
    if (!data?.id) return

    try {
      setIsLoading(true)
      const reply = await getClinicByUserId(data.id)
      setClinicData(reply)
    } catch (error: any) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!id) return

    let shouldUpdateState = true

    const loadData = async () => {
      try {
        setIsLoading(true)
        const reply = await getUserById(id)
        if (shouldUpdateState) {
          setData(reply)
        }
      } catch (error: any) {
      } finally {
        setIsLoading(false)
      }
    }

    loadData()

    return () => {
      shouldUpdateState = false
    }
  }, [id])

  useEffect(() => {
    if (data?.roles.includes(CLINIC)) {
      getClinicData()
    }
  }, [data?.roles])

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <Box display='flex' alignItems='center'>
        <IconButton onClick={() => navigate(-1)}>
          <ChevronLeft />
        </IconButton>
      </Box>
      <h1>Edição de usuário</h1>
      <InputField control={control} name='email' label='Email' />
      <InputField control={control} name='whatsapp' label='Whatsapp' />
    </Container>
  )
}

export default UserDetail
