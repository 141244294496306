import client from 'services/client'
import {
  CreateQuestionDTO,
  QuestionIdProps,
  QuestionProps,
  QuestionSubmitData,
} from 'types/questions-types'

export const createQuestion = async (body: CreateQuestionDTO) => {
  try {
    const { data } = await client.post<QuestionProps>('/questions', body)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getQuestions = async () => {
  try {
    const { data } = await client.get<QuestionProps[]>('/questions')

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getQuestionsById = async (id: string) => {
  try {
    const { data } = await client.get<QuestionIdProps>(`/questions/${id}`)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getQuestionHistoricById = async (id: string) => {
  try {
    const { data }: any = await client.get(`/questions/historic/${id}`)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const updateQuestion = async (id: string, body: QuestionSubmitData) => {
  try {
    const { data }: any = await client.patch(`/questions/${id}`, body)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
