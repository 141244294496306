import { useState, useEffect, FC, ComponentProps } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from 'contexts'
import { signIn, signUp, checkAuth, getMyInfos } from 'services'
import { getUserInfos } from 'helpers'
import { SignInBody } from 'types/services'

function AuthProvider({ children }: ComponentProps<FC>): JSX.Element {
  const [user, setUser] = useState<any>(null) // TODO outdated
  const [userInfos, setUserInfos] = useState<any>(null)
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()

  const fetchUser = async () => {
    try {
      setLoading(true)

      await checkAuth()
      const data = await getMyInfos()
      const userInfos = getUserInfos()

      setUser(userInfos)
      setUserInfos(data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchUser()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const signin = async (params: SignInBody, callback?: VoidFunction) => {
    try {
      const data = await signIn(params)

      localStorage.setItem('wida:token', data?.accessToken)
      const userInfos = getUserInfos()

      if (!userInfos) throw new Error('Ocorreu um erro ao realizar o login')

      const myInfos = await getMyInfos()
      setUserInfos(myInfos)
      setUser(userInfos)

      if (callback) callback()
    } catch (error: any) {
      // registerTrack('Credenciais Invalidas')
      throw new Error(error.message)
    }
  }

  const signup = async (params: any, callback?: VoidFunction) => {
    try {
      const data = await signUp(params)

      localStorage.setItem('wida:token', data?.accessToken)
      const userInfos = getUserInfos()

      if (!userInfos) throw new Error('Ocorreu um erro ao realizar o cadastro')

      const myInfos = await getMyInfos()
      setUserInfos(myInfos)

      setUser(userInfos)
      if (callback) callback()
    } catch (error: any) {
      throw new Error(error.message)
    }
  }

  const signout = (type: string) => {
    setUser(null)
    setUserInfos(null)
    localStorage.removeItem('wida:token')
    navigate(`/signin/${type}`)
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        signin,
        signup,
        signout,
        fetchUser,
        userInfos,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
