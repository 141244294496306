import Header from 'components/header/header'
import { ReactNode } from 'react'
import Sidebar from 'routes/sidebar/sidebar'
import { Container } from './default-layout-styles'

interface DefaultLayoutProps {
  children?: ReactNode
}

function DefaultLayout({ children }: DefaultLayoutProps) {
  return (
    <>
      <Header />
      <Sidebar content={<Container>{children}</Container>} />
    </>
  )
}

export default DefaultLayout
