import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

export const LogoTitle = styled(Typography)({
  whiteSpace: 'pre-line',
  color: '#FFFFFF',
  marginBottom: '20px'
})

export const LogoText = styled(Typography)({
  whiteSpace: 'pre-line',
  color: '#FFFFFF',
  fontSize: '16px',
  marginBottom: '80px'
})
