export const defaultButtonStyle = {
  height: '32px',
  textTransform: 'none',
  fontSize: '14px',
  lineHeight: '18px',
  boxShadow: 'none',
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  '&:disabled': {
    color: '#ffffff',
    backgroundColor: '#d6d6d6',
  },
} as const
