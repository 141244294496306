import { Container, EnvironmentText } from './header-styles'
import HeaderLogo from 'images/wida-logo.svg'

enum Environment {
  DEV = 'dev',
  HOMOLOG = 'homolog',
}

const URL = window.location.href

const config = {
  dev: {
    name: Environment.DEV,
    color: '#15159e',
  },
  homolog: {
    name: Environment.HOMOLOG,
    color: '#ffa600',
  },
}

function Header() {
  const getEnvironment = (): { name?: string; color: string } | undefined => {
    if (URL.includes(Environment.DEV)) {
      return config[Environment.DEV]
    }
    if (URL.includes(Environment.HOMOLOG)) {
      return config[Environment.HOMOLOG]
    }
  }

  return (
    <Container backgroundColor={getEnvironment()?.color}>
      <img src={HeaderLogo} alt='logo' />
      <EnvironmentText>{getEnvironment()?.name}</EnvironmentText>
    </Container>
  )
}

export default Header
