import client from './client'
import { TagQuestionsOrder } from 'types/services/question-tags'

export const getTagQuestionsById = async (id: string) => {
  try {
    const { data }: any = await client.get(`questions-tags`, {
      params: { tagId: id },
    })

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const tagQuestionsOrder = async (
  id: string,
  order: TagQuestionsOrder[]
) => {
  try {
    const { data }: any = await client.post(
      `questions-tags/tag/order/${id}`,
      order
    )

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
