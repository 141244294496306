import { useEffect, useState } from 'react'

import { Table } from 'components'
import { getPatientsWithCount } from 'services'
import { Container } from 'styles/container'
import { PatientWithCountProps } from 'types/patients-types'

export const Patients = () => {
  const [data, setData] = useState<PatientWithCountProps[]>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let shouldUpdateState = true

    const loadData = async () => {
      try {
        setIsLoading(true)
        const reply = await getPatientsWithCount()
        if (shouldUpdateState) {
          setData(reply)
        }
      } catch (error: any) {
      } finally {
        setIsLoading(false)
      }
    }

    loadData()

    return () => {
      shouldUpdateState = false
    }
  }, [])

  return (
    <Container>
      <h1 style={{ marginTop: 0 }}>Pacientes e cuidadores</h1>
      <Table
        rows={
          data?.map((item) => ({
            ...item,
            acceptedSentInvitesCount: Number(item.acceptedSentInvitesCount),
            completedFormsCount: Number(item.completedFormsCount),
            sentInvitesCount: Number(item.sentInvitesCount),
          })) || []
        }
        columns={[
          { field: 'id', headerName: 'Id' },
          { field: 'cpf', headerName: 'Cpf', width: 200 },
          {
            field: 'completedFormsCount',
            headerName: 'Questionários respondidos',
            width: 200,
          },
          {
            field: 'sentInvitesCount',
            headerName: 'Convites enviados',
            width: 200,
          },
          {
            field: 'acceptedSentInvitesCount',
            headerName: 'Convites aceitos',
            width: 200,
          },
        ]}
        isLoading={isLoading}
      />
    </Container>
  )
}
