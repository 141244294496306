import { GridColDef, GridToolbarContainer } from '@mui/x-data-grid'

import ExportButton from 'components/export-button/export-button'

interface CustomToolbarProps {
  columns: GridColDef[]
  fileName?: string
  exportData?: any[]
}

function CustomToolbar({ columns, fileName, exportData }: CustomToolbarProps) {
  return (
    <GridToolbarContainer>
      <ExportButton
        columns={columns}
        fileName={fileName}
        exportData={exportData}
      />
    </GridToolbarContainer>
  )
}

export default CustomToolbar
