import { Box } from '@mui/material'
import { Draggable } from 'react-beautiful-dnd'
import { useNavigate } from 'react-router-dom'

import { TagQuestionsProps } from 'types/services/question-tags'
import { Container, OrderText } from './question-card-styles'

interface QuestionCardProps {
  question: TagQuestionsProps
  index: number
  isDisabled?: boolean
}

function QuestionCard({ question, index, isDisabled }: QuestionCardProps) {
  const navigate = useNavigate()

  return (
    <Draggable draggableId={question.id} index={index}>
      {(provided, snapshot) => (
        <Container
          ref={provided.innerRef}
          position='relative'
          isdragging={snapshot.isDragging.toString()}
          onClick={() =>
            isDisabled &&
            navigate('/questions/details', {
              state: { id: question.id },
            })
          }
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Box display='flex' gap='12px'>
            <OrderText>{question.order}</OrderText>
            <p>{question.ultraShortVersion}</p>
          </Box>
          <p>{question.identifier}</p>
        </Container>
      )}
    </Draggable>
  )
}

export default QuestionCard
