import { CSSProperties } from 'react'
import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid'

import CustomToolbar from 'components/custom-toolbar/custom-toolbar'

interface TableProps {
  rows: any[]
  columns: GridColDef[]
  isLoading?: boolean
  height?: string
  fileName?: string
  exportData?: any[]
  style?: CSSProperties
  onRowClick?: (row: any) => void
}

function Table({
  rows,
  columns,
  isLoading,
  height = '80%',
  fileName,
  exportData,
  style,
  onRowClick,
}: TableProps) {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isLoading}
      onRowClick={onRowClick}
      localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
      components={{
        Toolbar: () => (
          <CustomToolbar
            columns={columns}
            fileName={fileName}
            exportData={exportData}
          />
        ),
      }}
      style={{
        ...style,
        height,
      }}
    ></DataGrid>
  )
}

export default Table
