import { useForm } from 'react-hook-form'
import {
  QuestionsBlocksProps,
  QuestionsBlocksSubmitData,
} from 'types/questions-blocks-types'

function useQuestionBlocksDetailsForm(data?: QuestionsBlocksProps) {
  const { control, handleSubmit, watch } = useForm<QuestionsBlocksSubmitData>({
    mode: 'onBlur',
    values: {
      name: data?.name || '',
    },
  })

  return { control, handleSubmit, watch }
}

export default useQuestionBlocksDetailsForm
