import { Box, css, styled } from '@mui/material'

interface ContainerProps {
  isdragging?: string
}

export const Container = styled(Box)<ContainerProps>`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin: 8px;
  padding: 0 12px;
  border-radius: 16px;
  border: 0.5px solid #ececec;

  ${({ isdragging }) =>
    !!isdragging
      ? css`
          cursor: pointer !important;
        `
      : css`
          cursor: grab !important;
        `}
`

export const OrderText = styled('p')`
  font-weight: bold;
`
