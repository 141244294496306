import { styled } from '@mui/material/styles'

export const StyledInput = styled('textarea')({
  fontFamily: "'Zen Kaku Gothic Antique', sans-serif",
  padding: '5px 15px',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '23px',
  'input[disabled]': {
    color: '#353F48',
    WebkitTextFillColor: '#353F48',
  },
})
