/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ChevronLeft, CloseOutlined, EditOutlined } from '@mui/icons-material'
import { Box, Checkbox, Grid, IconButton } from '@mui/material'
import { format } from 'date-fns'

import {
  Button,
  IconLink,
  Loading,
  QuestionDiffModal,
  Switch,
} from 'components'
import {
  createQuestion,
  // getQuestionHistoricById,
  getQuestionsBlocks,
  getQuestionsById,
  getTags,
  updateQuestion,
} from 'services'
import { Container } from 'styles/container'
import { QuestionIdProps, QuestionSubmitData } from 'types/questions-types'
import { InputField, SelectField, TextAreaField } from 'components/inputs'
import useQuestionDetailForm from './question-detail-form'
import { useNotification } from 'hooks'
import { QuestionsBlocksProps } from 'types/questions-blocks-types'
import { TagProps } from 'types/tags-types'
import { SubTitle } from './question-detail-styles'

function QuestionDetail() {
  const [data, setData] = useState<QuestionIdProps>()
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  // const [questionHistory, setQuestionHistory] = useState<QuestionProps[]>()
  // const [selectedQuestionHistory, setSelectedQuestionHistory] =
  //   useState<QuestionProps>()
  const [blocks, setBlocks] = useState<QuestionsBlocksProps[]>()
  const [tags, setTags] = useState<TagProps[]>([])
  const [selectedTags, setSelectedTags] = useState<TagProps[]>([])

  const { control, handleSubmit, watch, reset } = useQuestionDetailForm(
    // selectedQuestionHistory ||
    data,
    blocks?.find((item) => item.name === data?.block)?.id
  )
  const { errorToast, successToast } = useNotification()
  const navigate = useNavigate()

  const { state } = useLocation()

  const id = state?.id

  const handleValidate = (data?: QuestionSubmitData) => {
    if (!data) return

    // if (selectedQuestionHistory) {
    //   setIsModalOpen(true)
    //   return
    // } else {
    onSubmit(data)
    // }
  }

  const onSubmit = async (data: QuestionSubmitData) => {
    try {
      setIsLoading(true)
      if (!id) {
        await createQuestion({
          example: data?.example,
          question3rdM: data?.question3rdM,
          question3rdF: data?.question3rdF,
          questionSelfM: data?.questionSelfM,
          questionSelfF: data?.questionSelfF,
          ultraShortVersion: data?.ultraShortVersion,
          tagIds: selectedTags.map((item) => item.id),
          questionBlockId: data?.blockId || '',
          observation: data?.observation,
          shortVersionM: 'n/a',
          shortVersionF: 'n/a',
        })
        successToast('Pergunta criada!')
        navigate(-1)
      } else if (isEditing) {
        await updateQuestion(id, {
          isActive: data?.isActive,
          example: data?.example,
          question3rdM: data?.question3rdM,
          question3rdF: data?.question3rdF,
          questionSelfM: data?.questionSelfM,
          questionSelfF: data?.questionSelfF,
          ultraShortVersion: data?.ultraShortVersion,
          blockId: data?.blockId,
          tagIds: selectedTags.map((item) => item.id),
          observation: data?.observation,
        })
        successToast('Pergunta atualizada!')
        loadData()
        loadBlocksAndTags()
      }
    } catch (error: any) {
      errorToast(error.message)
    } finally {
      setIsLoading(false)
      setIsEditing(false)
    }
  }

  const loadData = async () => {
    try {
      setIsLoading(true)
      const [
        reply,
        // , replyHistoric
      ] = await Promise.all([
        getQuestionsById(id),
        // getQuestionHistoricById(id)
      ])

      setData(reply)
      // setQuestionHistory(replyHistoric)
    } catch (error: any) {
      errorToast(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const loadBlocksAndTags = async () => {
    try {
      const [replyQuestionBlocks, replyTags] = await Promise.all([
        getQuestionsBlocks(),
        getTags(),
      ])

      setBlocks(replyQuestionBlocks)
      setTags(replyTags)
    } catch (error: any) {
      errorToast(error.message)
    }
  }

  useEffect(() => {
    if (!id) return

    loadData()
  }, [id])

  useEffect(() => {
    loadBlocksAndTags()
  }, [])

  useEffect(() => {
    if (!tags) return

    setSelectedTags(
      tags
        .map((item) => {
          if (data?.tags?.find((i) => i === item.name)) {
            return item
          }
        })
        .filter(Boolean) as never
    )
  }, [data?.tags, tags])

  useEffect(() => {
    if (!isEditing) reset()
  }, [isEditing])

  return (
    <Container>
      <QuestionDiffModal
        data={data}
        newData={watch()}
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={(data) => {
          setIsModalOpen(false)
          onSubmit(data)
        }}
      />

      <Box display='flex' flex={1}>
        <Box display='flex' flexDirection='column' flex={4}>
          <Loading isLoading={isLoading} />

          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            <Box display='flex' alignItems='center' gap='12px'>
              <IconButton onClick={() => navigate(-1)}>
                <ChevronLeft />
              </IconButton>
              {id && (
                <>
                  Identificador:{' '}
                  {
                    // selectedQuestionHistory?.identifier ||
                    data?.identifier
                  }
                </>
              )}
            </Box>
            {id && (
              <Box display='flex' alignItems='center' gap='24px'>
                <Button
                  type='button'
                  color='secondary'
                  disabled
                  sx={{ width: '200px' }}
                >
                  Visualizar histórico
                </Button>
              </Box>
            )}
          </Box>
          {/* <h1 style={{ marginTop: 0 }}>
                {selectedQuestionHistory?.ultraShortVersion ||
                  data?.ultraShortVersion}{' '}
                {selectedQuestionHistory &&
                  `- versão de: ${format(
                    new Date(selectedQuestionHistory.createdAt),
                    'dd/MM/yyyy HH:mm'
                  )}`}
              </h1> */}

          <Box
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            flex='1'
            component='form'
            onSubmit={handleSubmit(handleValidate)}
          >
            <Grid container rowSpacing={2} columnSpacing={4}>
              <Grid item xs={4}>
                {id && !isEditing ? (
                  <Box display='flex' flexDirection='column'>
                    <h1>{watch('ultraShortVersion')}</h1>
                  </Box>
                ) : (
                  <Box display='flex' flexDirection='column'>
                    <SubTitle>Versão curta</SubTitle>
                    <InputField
                      control={control}
                      name='ultraShortVersion'
                      lengthLimit={30}
                    />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                <SubTitle>Perguntas</SubTitle>
              </Grid>
              <Grid item xs={12}>
                {id && !isEditing ? (
                  <Box display='flex' flexDirection='column'>
                    <strong>Masculino própria pessoa</strong>
                    {watch('questionSelfM')}
                  </Box>
                ) : (
                  <InputField
                    control={control}
                    name='questionSelfM'
                    label='Masculino própria pessoa'
                    disabled={id && !isEditing}
                    lengthLimit={80}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {id && !isEditing ? (
                  <Box display='flex' flexDirection='column'>
                    <strong>Feminino própria pessoa</strong>
                    {watch('questionSelfF')}
                  </Box>
                ) : (
                  <InputField
                    control={control}
                    name='questionSelfF'
                    label='Feminino própria pessoa'
                    disabled={id && !isEditing}
                    lengthLimit={80}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {id && !isEditing ? (
                  <Box display='flex' flexDirection='column'>
                    <strong>Masculino terceira pessoa</strong>
                    {watch('question3rdM')}
                  </Box>
                ) : (
                  <InputField
                    control={control}
                    name='question3rdM'
                    label='Masculino terceira pessoa'
                    disabled={id && !isEditing}
                    lengthLimit={80}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {id && !isEditing ? (
                  <Box display='flex' flexDirection='column'>
                    <strong>Feminino terceira pessoa</strong>
                    {watch('question3rdF')}
                  </Box>
                ) : (
                  <InputField
                    control={control}
                    name='question3rdF'
                    label='Feminino terceira pessoa'
                    disabled={id && !isEditing}
                    lengthLimit={80}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {id && !isEditing ? (
                  <Box display='flex' flexDirection='column'>
                    <strong>Explicação</strong>
                    {watch('example')}
                  </Box>
                ) : (
                  <InputField
                    control={control}
                    name='example'
                    label='Explicação'
                    disabled={id && !isEditing}
                    lengthLimit={160}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <SubTitle>Domínios</SubTitle>
              </Grid>
              <Grid container width='60%'>
                {tags.map((item) => (
                  <Grid
                    key={item.id}
                    item
                    xs={4}
                    xl={3}
                    style={{ marginLeft: 24 }}
                  >
                    <Box display='flex' alignItems='center'>
                      <Checkbox
                        id={item.id}
                        name={item.name}
                        checked={!!selectedTags.find((i) => i.id === item.id)}
                        disabled={id && !isEditing}
                        color='secondary'
                        onChange={(value) => {
                          const tagFound = tags.find(
                            (item) => item.name === value.target.name
                          )

                          if (
                            selectedTags.find(
                              (item) => item.name === value.target.name
                            )
                          ) {
                            setSelectedTags([
                              ...selectedTags.filter(
                                (item) => item.name !== value.target.name
                              ),
                            ])
                          } else if (tagFound) {
                            setSelectedTags([...selectedTags, tagFound])
                          }
                        }}
                      />
                      <label htmlFor={item.id}>{item.name}</label>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12}>
                <SubTitle>Bloco</SubTitle>
              </Grid>
              <Grid item xs={3} style={{ paddingTop: 0 }}>
                <SelectField
                  control={control}
                  name='blockId'
                  options={
                    blocks?.map((item) => {
                      return {
                        label: item.name,
                        value: item.id,
                      }
                    }) || [{ label: '', value: '' }]
                  }
                  disabled={id && !isEditing}
                />
              </Grid>
              <Grid item xs={8} />
              <Grid item xs={12}>
                <Switch
                  control={control}
                  name='isActive'
                  label='Ativo'
                  width={36}
                  disabled={id && !isEditing}
                />
              </Grid>
              <Grid item xs={4}>
                <Box marginBottom='24px'>
                  <h3>Observação</h3>
                  <TextAreaField
                    control={control}
                    name='observation'
                    disabled={id && !isEditing}
                    height='100px'
                  />
                </Box>

                {data?.updatedAt &&
                  `Última edição: ${format(
                    new Date(data.updatedAt),
                    'dd/MM/yyyy HH:mm'
                  )}`}
              </Grid>
            </Grid>

            <Box
              display='flex'
              alignItems='center'
              justifyContent='flex-end'
              marginTop='24px'
              gap='24px'
            >
              {id && (
                <IconLink
                  startIcon={
                    id && isEditing ? <CloseOutlined /> : <EditOutlined />
                  }
                  onClick={() => setIsEditing(!isEditing)}
                >
                  {id && isEditing ? 'cancelar' : 'editar'}
                </IconLink>
              )}
              <Button
                type='submit'
                color='secondary'
                disabled={id && !isEditing}
                sx={{ width: '200px' }}
              >
                {id ? 'Salvar' : 'Criar'}
              </Button>
            </Box>
          </Box>
        </Box>

        {/* <Box display='flex' borderLeft='1px solid #f0f0f0' flex={1}>
          <EditHistoric
            data={questionHistory}
            selectedItemId={selectedQuestionHistory?.id}
            onItemClick={(value) => setSelectedQuestionHistory(value)}
          />
        </Box> */}
      </Box>
    </Container>
  )
}

export default QuestionDetail
