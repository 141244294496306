import { Box, Typography } from '@mui/material'
import { SubmitHandler } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button, LogoLayout } from 'components'
import { InputField } from 'components/inputs'
import { useAuthContext } from 'contexts'
import { useNotification } from 'hooks'
import { sanitizeString } from 'helpers'
import { IFormInput } from './sign-in-types'
import useFormSignIn from './sign-in-form'

function SignIn({ type }: any): JSX.Element {
  const { control, handleSubmit, watch } = useFormSignIn()
  const navigate = useNavigate()
  const { signin } = useAuthContext()
  const { successToast, errorToast } = useNotification()

  const location: any = useLocation()
  const cpf = watch('cpf')
  const pass = watch('password')

  const onSubmit: SubmitHandler<IFormInput> = async (params) => {
    try {
      const body = {
        cpf: sanitizeString(params.cpf),
        password: params.password,
      }
      await signin(body)
      successToast('Login realizado com sucesso')
      const from = (location?.state?.from?.pathname as string) || '/'
      navigate(from, { replace: true })
    } catch (error: any) {
      console.error(error)
      errorToast(error.message)
    }
  }

  return (
    <LogoLayout>
      <Box
        display='flex'
        flexDirection='column'
        flexGrow={1}
        sx={{
          alignItems: { md: 'end' },
          width: { xs: '100%', md: '50%' },
          padding: { xs: '15%', md: '50px 10%' },
        }}
      >
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          flexGrow={1}
          width='100%'
          sx={{
            zIndex: 1,
            padding: {
              xs: '20% 0',
              md: '0',
            },
          }}
        >
          <Typography variant='h3' color={'#657787'}>
            Faça seu login
          </Typography>
          <Box component='form' onSubmit={handleSubmit(onSubmit)}>
            <Box mt={3} mb={2}>
              <InputField
                name='cpf'
                control={control}
                label='CPF'
                mask='000.000.000-00'
              />
            </Box>
            <Box mb={4}>
              <InputField
                type='password'
                name='password'
                control={control}
                label='Senha'
              />
            </Box>
            <Button
              disabled={!cpf || !pass}
              type='submit'
              style={{ width: '50%' }}
            >
              Entrar
            </Button>
          </Box>
        </Box>
      </Box>
    </LogoLayout>
  )
}

export default SignIn
