/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Box, IconButton } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { ChevronLeft, CloseOutlined, EditOutlined } from '@mui/icons-material'

import { Container } from 'styles/container'
import { TagProps, TagUpdateData } from 'types/tags-types'
import useTagDetailForm from './tags-details-form'
import { useNotification } from 'hooks'
import {
  createTag,
  getTagById,
  getTagQuestionsById,
  tagQuestionsOrder,
  updateTag,
} from 'services'
import { Button, IconLink, Loading, QuestionsContainer } from 'components'
import { InputField, TextAreaField } from 'components/inputs'
import { capitalizeWordsOnSentence } from 'helpers'
import { TagQuestionsProps } from 'types/services/question-tags'
import { format } from 'date-fns'

function TagsDetails() {
  const [data, setData] = useState<TagProps>()
  const [tagQuestions, setTagQuestions] = useState<TagQuestionsProps[]>()
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isOrderEditing, setIsOrderEditing] = useState(false)

  const { control, handleSubmit } = useTagDetailForm(data)
  const { errorToast, successToast } = useNotification()
  const navigate = useNavigate()

  const { state } = useLocation()

  const id = state?.id

  const onSubmit = async (data: TagUpdateData) => {
    const order = tagQuestions?.map((item, index) => {
      return {
        order: index + 1,
        id: item.id,
      }
    })

    try {
      setIsLoading(true)
      if (!id) {
        await createTag(data)
        successToast('Domínio criado!')
        navigate(-1)
      } else if (isEditing) {
        await updateTag(id, data)
        successToast('Domínio atualizado!')
        loadData()
      }

      if (isOrderEditing && order) {
        await tagQuestionsOrder(id, order)
        successToast('Ordem das perguntas atualizada!')
        loadData()
      }
    } catch (error: any) {
      errorToast(error.message)
    } finally {
      setIsLoading(false)
      setIsEditing(false)
      setIsOrderEditing(false)
    }
  }

  const loadData = async () => {
    try {
      setIsLoading(true)
      const reply = await getTagById(id)
      const replyTagQuestions = await getTagQuestionsById(id)
      setData(reply)
      setTagQuestions(replyTagQuestions)
    } catch (error: any) {
      errorToast(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!id) return

    loadData()
  }, [id])

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <Box display='flex' alignItems='center' gap='12px'>
        <IconButton onClick={() => navigate(-1)}>
          <ChevronLeft />
        </IconButton>
      </Box>

      <Box display='flex' alignItems='center' gap='24px'>
        {id ? (
          <h1 style={{ marginTop: 0, marginBottom: 0 }}>
            {data?.name && capitalizeWordsOnSentence(data?.name)}
          </h1>
        ) : (
          <h1>Criação de Domínio</h1>
        )}

        {id && (
          <IconLink
            startIcon={isEditing ? <CloseOutlined /> : <EditOutlined />}
            onClick={() => !isOrderEditing && setIsEditing(!isEditing)}
          >
            {isEditing ? 'cancelar' : 'editar'}
          </IconLink>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        flex={1}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box width='50%'>
          <InputField
            control={control}
            name='name'
            label='Nome'
            disabled={id && !isEditing}
          />
          {id && (
            <Box>
              <Box display='flex' alignItems='center' gap='24px'>
                <h3>Perguntas desse domínio</h3>
                {id && (
                  <IconLink
                    startIcon={
                      isOrderEditing ? <CloseOutlined /> : <EditOutlined />
                    }
                    onClick={() =>
                      !isEditing && setIsOrderEditing(!isOrderEditing)
                    }
                  >
                    {isOrderEditing ? 'cancelar' : 'editar ordem'}
                  </IconLink>
                )}
              </Box>
              <QuestionsContainer
                tagQuestions={tagQuestions}
                setTagQuestions={setTagQuestions}
                isDisabled={!isOrderEditing}
              />
            </Box>
          )}
          <h3>Observação</h3>
          <TextAreaField
            control={control}
            name='observation'
            disabled={id && !isEditing}
          />
          {data?.updatedAt && (
            <p>
              Última edição:{' '}
              {format(new Date(data?.updatedAt), 'dd/MM/yyyy hh:mm')}
            </p>
          )}
        </Box>

        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          marginTop='24px'
        >
          <Button
            type='submit'
            color='secondary'
            disabled={id && !isEditing && !isOrderEditing}
            sx={{ width: '200px' }}
          >
            {id ? (isOrderEditing ? 'Salvar ordem' : 'Salvar') : 'Criar'}
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

export default TagsDetails
