import { Box } from '@mui/material'
import { LogoContainer } from './logo-styles'
import logoW from 'images/w.svg'
import LogoTextContainer from './logo-text-container/logo-text-container'

interface IDashboardLayoutProps {
  children: JSX.Element
}

function LogoLayout({ children }: IDashboardLayoutProps): JSX.Element {
  return (
    <Box
      display='flex'
      height={window.innerHeight}
      flexDirection={{ xs: 'column', md: 'row' }}
    >
      <LogoContainer width={{ xs: '100%', md: '50%' }}>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <img
            src={logoW}
            alt='Wida'
            height={'65%'}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
        </Box>
        <Box
          display='flex'
          sx={{
            height: { md: window.innerHeight },
            flexDirection: { xs: 'row', md: 'column' },
            justifyContent: { xs: 'center', md: 'flex-end' },
            padding: { xs: '20% 0', md: '65px' },
          }}
        >
          <LogoTextContainer />
        </Box>
      </LogoContainer>
      {children}
    </Box>
  )
}

export default LogoLayout
