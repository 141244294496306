import { Close } from '@mui/icons-material'
import { Box, Grid, Modal } from '@mui/material'

import Button from 'components/button/button'
import { QuestionIdProps, QuestionSubmitData } from 'types/questions-types'

interface QuestionDiffModalProps {
  data?: QuestionIdProps
  newData?: QuestionSubmitData
  isModalOpen: boolean
  onClose?: () => void
  onConfirm?: (data: QuestionSubmitData) => void
}

function QuestionDiffModal({
  data,
  newData,
  isModalOpen,
  onClose,
  onConfirm = () => {},
}: QuestionDiffModalProps) {
  return (
    <Modal
      open={isModalOpen}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        width={1400}
        height={600}
        borderRadius='24px'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: '#fff',
          boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.5)',
          padding: '24px',
        }}
      >
        <Box display='flex' justifyContent='space-between'>
          <h2 style={{ margin: 0 }}>Review</h2>
          <Close onClick={onClose} style={{ cursor: 'pointer' }} />
        </Box>

        <Box display='flex' flexDirection='row' gap='32px'>
          <Grid container rowSpacing={2} columnSpacing={8}>
            <Grid item xs={6}>
              <h3 style={{ margin: 0 }}>Antigo</h3>
            </Grid>
            <Grid item xs={6}>
              <h3 style={{ margin: 0 }}>Novo</h3>
            </Grid>
            <Grid item xs={3}>
              <p>Masculino</p>
            </Grid>
            <Grid item xs={3}>
              <p>Feminino</p>
            </Grid>
            <Grid item xs={3}>
              <p>Masculino</p>
            </Grid>
            <Grid item xs={3}>
              <p>Feminino</p>
            </Grid>
            <Grid item xs={3}>
              {data?.question3rdM}
            </Grid>
            <Grid item xs={3}>
              {data?.question3rdF}
            </Grid>
            <Grid item xs={3}>
              {newData?.question3rdM}
            </Grid>
            <Grid item xs={3}>
              {newData?.question3rdF}
            </Grid>
            <Grid item xs={3}>
              {data?.questionSelfM}
            </Grid>
            <Grid item xs={3}>
              {data?.questionSelfF}
            </Grid>
            <Grid item xs={3}>
              {newData?.questionSelfM}
            </Grid>
            <Grid item xs={3}>
              {newData?.questionSelfF}
            </Grid>
          </Grid>
        </Box>

        <Box display='flex' justifyContent='space-between'>
          <Button
            type='button'
            variant='outlined'
            sx={{
              width: '200px',
            }}
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            type='submit'
            sx={{
              width: '200px',
            }}
            onClick={() => newData && onConfirm(newData)}
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default QuestionDiffModal
