import { QuestionsBlocksSubmitData } from 'types/questions-blocks-types'
import client from './client'

export const createQuestionBlocks = async (name: string) => {
  try {
    const { data }: any = await client.post('/questions-blocks', { name })

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getQuestionsBlocks = async () => {
  try {
    const { data }: any = await client.get('/questions-blocks')

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getQuestionsBlocksById = async (id: string) => {
  try {
    const { data }: any = await client.get(`/questions-blocks/${id}`)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const updateQuestionBlock = async (
  id: string,
  body: QuestionsBlocksSubmitData
) => {
  try {
    const { data }: any = await client.patch(`/questions-blocks/${id}`, body)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
