import { useForm } from 'react-hook-form'
import { UserProps } from 'types/users-types'
import { IFormInput } from './user-detail-types'

function useFormUserDetail(data?: UserProps) {
  const { control, handleSubmit, watch } = useForm<IFormInput>({
    mode: 'onBlur',
    values: {
      email: data?.email || '',
      whatsapp: data?.whatsapp || '',
    },
  })

  return { control, handleSubmit, watch }
}

export default useFormUserDetail
