import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { GradientLink } from './gradient-link'

function SignUpHeader({ type }: any): JSX.Element {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/invite_clinic')
  }

  return (
    <Typography variant='h5' style={{ fontSize: '16px' }} sx={{ zIndex: 1 }}>
      Não tem uma conta?{' '}
      {type === 'clinic' ? (
        <GradientLink
          href='https://www.wida.app/?register=1'
          rel='noreferrer'
          target='_blank'
        >
          Cadastre-se
        </GradientLink>
      ) : (
        <GradientLink onClick={handleClick}>Cadastre-se</GradientLink>
      )}
    </Typography>
  )
}

export default SignUpHeader
