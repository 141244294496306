import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'

import { reorder } from 'helpers'
import { Box } from '@mui/material'
import QuestionCard from 'components/question-card/question-card'
import { TagQuestionsProps } from 'types/services/question-tags'

interface QuestionsContainerProps {
  tagQuestions?: TagQuestionsProps[]
  isDisabled?: boolean
  setTagQuestions?: (tagQuestions: TagQuestionsProps[]) => void
}

function QuestionsContainer({
  tagQuestions,
  isDisabled,
  setTagQuestions = () => {},
}: QuestionsContainerProps) {
  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!tagQuestions) return
    if (!destination) return

    const newItems = reorder(tagQuestions, source.index, destination.index)

    setTagQuestions(newItems)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId='droppable-list'
        direction='vertical'
        isDropDisabled={isDisabled}
      >
        {(provided) => (
          <Box ref={provided.innerRef} {...provided.droppableProps}>
            {tagQuestions?.map((item, index) => (
              <QuestionCard
                key={item.id}
                question={item}
                index={index}
                isDisabled={isDisabled}
              />
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default QuestionsContainer
