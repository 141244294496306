import client from 'services/client'
import { ClinicProps, ClinicWithCount } from 'types/clinics-types'

export const getClinicPatients = async () => {
  try {
    const { data }: any = await client.get('form-requests?requestedByMe=true')
    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getClinicInfo = async () => {
  try {
    const { data }: any = await client.get('/clinics/my-infos')
    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const updateClinicInfo = async (body: any) => {
  try {
    await client.patch('/clinics/my-infos', body)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getClinicNotifications = async () => {
  try {
    const { data }: any = await client.get('/notifications')
    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const setNotificationAsViewed = async (id: string) => {
  try {
    await client.patch(`/notifications/${id}/viewed`)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const setNotificationAsRead = async (id: string) => {
  try {
    await client.patch(`/notifications/${id}/read`)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const createPatientRecord = async (body: any) => {
  try {
    const { data }: any = await client.post('/patient-medical-records', body)
    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const updatePatientRecord = async (body: any, recordId: string) => {
  try {
    await client.patch(`/patient-medical-records/${recordId}`, body)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const closePatientRecord = async (recordId: string) => {
  try {
    await client.post(`/patient-medical-records/${recordId}/close`)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const deletePatientRecord = async (recordId: string) => {
  try {
    await client.delete(`/patient-medical-records/${recordId}`)
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getPatientRecords = async (patientId: string) => {
  try {
    const { data } = await client.get(
      `patient-medical-records?patientId=${patientId}`
    )
    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getClinicById = async (id: string) => {
  try {
    const { data }: any = await client.get(`clinics/${id}`)
    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getClinicByUserId = async (id: string) => {
  try {
    const { data }: any = await client.get('clinics', {
      params: {
        userId: id,
      },
    })
    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getClinics = async () => {
  try {
    const { data } = await client.get<ClinicProps[]>('clinics')
    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getClinicsWithCount = async () => {
  try {
    const { data } = await client.get<ClinicWithCount[]>('clinics?withCount=1')
    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
