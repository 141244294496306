import { useEffect, useState } from 'react'

import { Table } from 'components'
import { Container } from 'styles/container'
import { InformantWithCountProps } from 'types/informants-types'
import { getInformantsWithCount } from 'services/informant'

export const Informants = () => {
  const [data, setData] = useState<InformantWithCountProps[]>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let shouldUpdateState = true

    const loadData = async () => {
      try {
        setIsLoading(true)
        const reply = await getInformantsWithCount()
        if (shouldUpdateState) {
          setData(reply)
        }
      } catch (error: any) {
      } finally {
        setIsLoading(false)
      }
    }

    loadData()

    return () => {
      shouldUpdateState = false
    }
  }, [])

  return (
    <Container>
      <h1 style={{ marginTop: 0 }}>Convidados</h1>
      <Table
        rows={
          data?.map((item) => ({
            ...item,
            completedFormsCount: Number(item.completedFormsCount),
          })) || []
        }
        columns={[
          { field: 'id', headerName: 'Id' },
          { field: 'cpf', headerName: 'Cpf', width: 200 },
          {
            field: 'completedFormsCount',
            headerName: 'Questionários respondidos',
            width: 200,
          },
        ]}
        isLoading={isLoading}
      />
    </Container>
  )
}
