import { Box } from '@mui/material'
import { LogoTitle } from './logo-text-container-styles'

function LogoTextContainer(): JSX.Element {
  const title = `Painel administrativo`

  return (
    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
      <LogoTitle variant='h2'>{title}</LogoTitle>
    </Box>
  )
}

export default LogoTextContainer
