import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'

import { Container } from 'styles/container'
import { TagProps } from 'types/tags-types'
import { getTags } from 'services'
import { Button, Table } from 'components'

function Tags() {
  const [data, setData] = useState<TagProps[]>()
  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()

  const handleRowClick = (row: any) => {
    navigate('/tags/details', {
      state: { id: row.id },
    })
  }

  useEffect(() => {
    let shouldUpdateState = true

    const loadData = async () => {
      try {
        setIsLoading(true)
        const reply = await getTags()
        if (shouldUpdateState) {
          setData(reply)
        }
      } catch (error: any) {
      } finally {
        setIsLoading(false)
      }
    }

    loadData()

    return () => {
      shouldUpdateState = false
    }
  }, [])

  return (
    <Container style={{ paddingTop: 0 }}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <h1>Domínios</h1>
        <Button
          type='button'
          color='secondary'
          onClick={() => navigate('/tags/details')}
          sx={{ width: '200px' }}
        >
          Adicionar
        </Button>
      </Box>
      <Table
        rows={data || []}
        columns={[
          { field: 'id', headerName: 'Id' },
          { field: 'name', headerName: 'Nome', width: 250 },
        ]}
        fileName='dominios'
        isLoading={isLoading}
        onRowClick={handleRowClick}
      />
    </Container>
  )
}

export default Tags
