import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'

import { getQuestions, getTags } from 'services'
import { QuestionProps } from '../../types/questions-types'
import { Button, Table } from 'components'
import { Container } from 'styles/container'
import { Box } from '@mui/material'
import { TagProps } from 'types/tags-types'

function Questions() {
  const [data, setData] = useState<QuestionProps[]>()
  const [tags, setTags] = useState<TagProps[]>()
  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()

  const handleRowClick = (row: any) => {
    navigate('/questions/details', {
      state: { id: row.id },
    })
  }

  useEffect(() => {
    let shouldUpdateState = true

    const loadData = async () => {
      try {
        setIsLoading(true)
        const [reply, replyTags] = await Promise.all([
          getQuestions(),
          getTags(),
        ])
        if (shouldUpdateState) {
          setData(
            reply.sort((a, b) => Number(a.identifier) - Number(b.identifier))
          )
          setTags(replyTags)
        }
      } catch (error: any) {
      } finally {
        setIsLoading(false)
      }
    }

    loadData()

    return () => {
      shouldUpdateState = false
    }
  }, [])

  return (
    <Container style={{ paddingTop: 0 }}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <h1>Perguntas</h1>
        <Button
          type='button'
          color='secondary'
          onClick={() => navigate('/questions/details')}
          sx={{ width: '200px' }}
        >
          Adicionar
        </Button>
      </Box>
      <Table
        rows={
          data?.map((item) => {
            return {
              ...item,
              identifier: Number(item.identifier),
              tags: item.questionTags
                .map((i) => tags?.find((tag) => tag.id === i.tagId)?.name)
                .join(', '),
              isActive: item.isActive ? 'Sim' : 'Não',
              updatedAt:
                item.updatedAt &&
                format(new Date(item.updatedAt), 'dd/MM/yyyy HH:mm'),
            }
          }) || []
        }
        columns={[
          { field: 'identifier', headerName: 'Identificador' },
          { field: 'ultraShortVersion', headerName: 'Pergunta', width: 300 },
          { field: 'tags', headerName: 'Domínios', width: 250 },
          { field: 'block', headerName: 'Bloco', width: 250 },
          { field: 'isActive', headerName: 'Ativo', width: 150 },
          { field: 'updatedAt', headerName: 'Última atualização', width: 150 },
        ]}
        fileName='perguntas'
        exportData={data}
        isLoading={isLoading}
        onRowClick={handleRowClick}
      />
    </Container>
  )
}

export default Questions
