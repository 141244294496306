import { createTheme } from '@mui/material/styles'

const grey = '#657787'

export const theme = createTheme({
  typography: {
    fontFamily: "'DM Sans', sans-serif",
    h3: {
      fontSize: '36px',
      fontWeight: '500',
      lineHeight: '47px',
    },
    h4: {
      color: grey,
      fontSize: '18px',
      lineHeight: '27px',
      fontWeight: '500',
    },
    h5: {
      color: grey,
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 'normal',
    },
    question: {
      color: grey,
      fontSize: '16px',
      lineHeight: '30px',
      fontWeight: 'normal',
    },
    title: {
      color: grey,
      fontSize: '22px',
      lineHeight: '30px',
      fontWeight: 'normal',
      letterSpacing: '0.04em',
    },
    text: {
      color: grey,
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'normal',
      letterSpacing: '0.04em',
    },
    link: {
      color: '#9D9CB5',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 'bold',
      letterSpacing: '0.04em',
      cursor: 'pointer',
    },
  },
  palette: {
    primary: {
      main: '#EE7262',
      contrastText: '#fff',
    },
    secondary: {
      main: '#58B8A9',
      contrastText: '#fff',
    },
    typography: {
      main: '#657787',
    },
    questionButton: {
      main: '#F4F5F6',
    },
    white: {
      main: '#FFFFFF',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#657787 #ffffff',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#ffffff',
            width: '5px',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: '#657787',
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
            {
              backgroundColor: '#657787',
            },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
            {
              backgroundColor: '#657787',
            },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
            {
              backgroundColor: '#657787',
            },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#ffffff',
          },
        },
      },
    },
  },
})

declare module '@mui/material/styles' {
  interface Palette {
    typography: Palette['primary']
    questionButton: Palette['primary']
    white: Palette['primary']
  }

  interface TypographyVariants {
    question: React.CSSProperties
    title: React.CSSProperties
    text: React.CSSProperties
    link: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    typography?: PaletteOptions['primary']
    questionButton?: PaletteOptions['primary']
    white?: PaletteOptions['primary']
  }
  interface TypographyVariantsOptions {
    question?: React.CSSProperties
    title?: React.CSSProperties
    text?: React.CSSProperties
    link?: React.CSSProperties
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    typography: true
    questionButton: true
    white: true
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    question: true
    title: true
    text: true
    link: true
  }
}
