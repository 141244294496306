import jwt_decode from 'jwt-decode'
import { CLINIC, PATIENT, CAREGIVER, INFORMANT } from 'helpers/constants'

interface IUserInfos {
  email: string
  roles: string[]
  personalInfos: {
    firstName: null | string
    lastName: null | string
    pronoun: null | string
    birthdate: null | string
  }
}

export const getUserInfos = () => {
  const token = localStorage.getItem('wida:token')
  if (!token) return

  const userInfos: IUserInfos = jwt_decode(String(token))

  return userInfos
}

export const userhasRole = (user: any, role: string) =>
  user?.roles?.includes(role.toLowerCase()) ||
  user?.roles?.includes(role.toUpperCase())

export const isUserClinic = (user: any) => userhasRole(user, CLINIC)

export const isUserPatient = (user: any) => userhasRole(user, PATIENT)

export const isUserCaregiver = (user: any) => userhasRole(user, CAREGIVER)

export const isUserInformant = (user: any) => userhasRole(user, INFORMANT)

// TODO multiroles
export const getUserRole = (user: any) => user?.roles[0]

export const formatUserName = (user: any) => {
  if (!user?.personalInfos) return ''
  return (
    (user?.personalInfos.firstName || '') +
    ' ' +
    (user?.personalInfos.lastName || '')
  ).trim()
}
