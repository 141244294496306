import { useForm } from 'react-hook-form'
import { QuestionIdProps, QuestionSubmitData } from 'types/questions-types'

function useQuestionDetailForm(data?: QuestionIdProps, blockId?: string) {
  const { control, handleSubmit, watch, reset } = useForm<QuestionSubmitData>({
    mode: 'onBlur',
    values: {
      blockId: blockId || '',
      tagIds: [''],
      question3rdF: data?.question3rdF || '',
      question3rdM: data?.question3rdM || '',
      questionSelfF: data?.questionSelfF || '',
      questionSelfM: data?.questionSelfM || '',
      isActive: !!data?.isActive,
      example: data?.example || '',
      ultraShortVersion: data?.ultraShortVersion || '',
      observation: data?.observation || '',
    },
  })

  return { control, handleSubmit, watch, reset }
}

export default useQuestionDetailForm
