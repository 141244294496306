/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, IconButton } from '@mui/material'

import {
  QuestionsBlocksProps,
  QuestionsBlocksSubmitData,
} from 'types/questions-blocks-types'
import {
  createQuestionBlocks,
  getQuestionsBlocksById,
  updateQuestionBlock,
} from 'services'
import { useNotification } from 'hooks'
import { Button, IconLink, Loading } from 'components'
import { ChevronLeft, CloseOutlined, EditOutlined } from '@mui/icons-material'
import useQuestionBlocksDetailsForm from './questions-blocks-details-form'
import { InputField } from 'components/inputs'

import { Container } from 'styles/container'
import { capitalizeWordsOnSentence } from 'helpers'

function QuestionsBlocksDetails() {
  const [data, setData] = useState<QuestionsBlocksProps>()
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const { control, handleSubmit } = useQuestionBlocksDetailsForm(data)
  const { errorToast, successToast } = useNotification()
  const navigate = useNavigate()

  const { state } = useLocation()

  const id = state?.id

  const onSubmit = async (data: QuestionsBlocksSubmitData) => {
    try {
      setIsLoading(true)
      if (id) {
        await updateQuestionBlock(id, data)
        successToast('Bloco atualizado!')
        loadData()
      } else {
        await createQuestionBlocks(data.name)
        successToast('Bloco criado!')
        navigate(-1)
      }
    } catch (error: any) {
      errorToast(error.message)
    } finally {
      setIsLoading(false)
      setIsEditing(false)
    }
  }

  const loadData = async () => {
    try {
      setIsLoading(true)
      const reply = await getQuestionsBlocksById(id)
      setData(reply)
    } catch (error: any) {
      errorToast(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!id) return

    loadData()
  }, [id])

  return (
    <Container>
      <Loading isLoading={isLoading} />

      <Box display='flex' alignItems='center'>
        <IconButton onClick={() => navigate(-1)}>
          <ChevronLeft />
        </IconButton>
      </Box>

      <h1>
        {(data?.name && capitalizeWordsOnSentence(data?.name)) ||
          'Criação de Bloco'}
      </h1>

      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        flex='1'
        component='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box width='50%'>
          <InputField
            control={control}
            name='name'
            label='Nome'
            disabled={id && !isEditing}
          />
        </Box>

        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          marginTop='24px'
          gap='24px'
        >
          {id && (
            <IconLink
              startIcon={isEditing ? <CloseOutlined /> : <EditOutlined />}
              onClick={() => setIsEditing(!isEditing)}
            >
              {isEditing ? 'cancelar' : 'editar'}
            </IconLink>
          )}
          <Button
            type='submit'
            color='secondary'
            disabled={id && !isEditing}
            sx={{ width: '200px' }}
          >
            {id ? 'Salvar' : 'Criar'}
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

export default QuestionsBlocksDetails
