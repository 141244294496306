import client from 'services/client'

export const getUsers = async () => {
  try {
    const { data }: any = await client.get('/users')

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getUserById = async (id: string) => {
  try {
    const { data }: any = await client.get(`/users/${id}`)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
