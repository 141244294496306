import { Box } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import FormControlMui from '@mui/material/FormControl'
import FormHelperTextMui from '@mui/material/FormHelperText'

import { StyledInput } from './text-area-field-styles'

interface TextAreaFieldProps {
  name: string
  control: Control<any>
  disabled?: boolean
  invalidChars?: string[]
  notShowError?: boolean
  height?: string
}

function TextAreaField({
  name,
  control,
  disabled,
  invalidChars = [],
  notShowError,
  height,
}: TextAreaFieldProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { invalid, error },
      }) => (
        <Box width='100%'>
          <FormControlMui
            disabled={disabled}
            variant='standard'
            fullWidth
            {...((!notShowError && { error: invalid }) as any)}
          >
            <StyledInput
              id={name}
              onBlur={onBlur}
              onChange={
                Boolean(invalidChars.length)
                  ? (value) => {
                      const sanitizedValue = invalidChars.reduce(
                        (acc: string, invalidChar: string) =>
                          acc.replaceAll(invalidChar, ''),
                        value.target.value
                      )
                      return onChange(sanitizedValue)
                    }
                  : onChange
              }
              value={value}
              color='primary'
              disabled={disabled}
              style={{
                maxWidth: '100%',
                maxHeight: '200px',
                height,
              }}
              sx={disabled ? { '&&&::before': { border: 'none' } } : void 0}
            />
            {!notShowError && invalid && (
              <FormHelperTextMui>
                {error?.message || 'Erro desconhecido'}
              </FormHelperTextMui>
            )}
          </FormControlMui>
        </Box>
      )}
    />
  )
}

export default TextAreaField
