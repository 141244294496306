import { MenuItem } from '@mui/material'
import { GridColDef, GridToolbarExportContainer } from '@mui/x-data-grid'
import {
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  useGridApiContext,
} from '@mui/x-data-grid'
import { GridApiCommunity } from '@mui/x-data-grid/internals'
import { utils, writeFile } from 'xlsx'

interface ExportButtonProps {
  columns: GridColDef[]
  fileName?: string
  exportData?: any[]
}

function ExportButton({
  columns,
  fileName = 'Planilha1',
  exportData,
}: ExportButtonProps) {
  const apiRef = useGridApiContext()

  const config = {
    keys: columns.map((item) => item.field),
    columnNames: columns.map((item) => item.headerName),
    sheetName: fileName,
    fileName: `${fileName}.xlsx`,
  }

  function getExcelData(apiRef: React.MutableRefObject<GridApiCommunity>) {
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef)
    const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef)

    const data = filteredSortedRowIds.map((id) => {
      const row: any = {}
      visibleColumnsField.forEach((field) => {
        row[field] = apiRef.current.getCellParams(id, field).value
      })
      return row
    })

    return data
  }

  const handleExport = (apiRef: React.MutableRefObject<GridApiCommunity>) => {
    const data = exportData || getExcelData(apiRef)

    const keys = Object.keys(data[0]).filter(
      (item) =>
        !item.includes('questionTags') &&
        !item.includes('shortVersionM') &&
        !item.includes('shortVersionF')
    )

    const rows = data.map((row) => {
      const mRow: any = {}
      for (const key of keys) {
        mRow[key] = row[key]

        if (mRow[key] instanceof Array) {
          mRow[key] = row[key].join(', ')
        }
      }
      return mRow
    })

    const cols = keys.map((key, index) => {
      const rowWidth = Math.max(...rows.map((item) => item[key]?.length))
      const columnWidth = config.columnNames[index]?.length

      return {
        width: (columnWidth && Math.max(rowWidth, columnWidth + 1)) || rowWidth,
      }
    })

    const worksheet = utils.json_to_sheet(rows)
    worksheet['!cols'] = cols
    utils.sheet_add_aoa(worksheet, [], {
      origin: 'A1',
    })

    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, config.sheetName)
    writeFile(workbook, config.fileName, { compression: true })
  }

  return (
    <GridToolbarExportContainer>
      <MenuItem
        onClick={() => {
          handleExport(apiRef)
        }}
      >
        Download Excel
      </MenuItem>
    </GridToolbarExportContainer>
  )
}

export default ExportButton
