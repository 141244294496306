import client from 'services/client'
import { TagProps, TagUpdateData } from 'types/tags-types'

export const createTag = async (body: TagUpdateData) => {
  try {
    const { data }: any = await client.post('tags', body)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getTags = async () => {
  try {
    const { data } = await client.get<TagProps[]>('tags')

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const getTagById = async (id: string) => {
  try {
    const { data }: any = await client.get(`tags/${id}`)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}

export const updateTag = async (id: string, body: TagUpdateData) => {
  try {
    const { data }: any = await client.patch(`tags/${id}`, body)

    return data
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || error?.message || 'Erro desconhecido'
    )
  }
}
