import { RequireAuthWrapper } from 'components'
import { ADMIN } from 'helpers/constants'
import { Route, Routes } from 'react-router-dom'

import {
  QuestionDetail,
  Questions,
  QuestionsBlocks,
  QuestionsBlocksDetails,
  SignIn,
  Tags,
  TagsDetails,
  Users,
  UsersDetail,
} from 'routes'
import DefaultLayout from 'routes/default-layout/default-layout'
import { Clinics } from 'routes/users/clinics/clinics'
import { Informants } from 'routes/users/informants/informants'
import { Patients } from 'routes/users/patients/patients'

declare global {
  interface Window {
    analytics: any
  }
}

function App() {
  return (
    <Routes>
      <Route path='signin'>
        <Route index path='admin' element={<SignIn />} />
      </Route>

      <Route
        path='/'
        element={
          <RequireAuthWrapper alowedRoles={[ADMIN]}>
            <DefaultLayout />
          </RequireAuthWrapper>
        }
      />

      <Route
        path='/questions'
        element={
          <RequireAuthWrapper>
            <DefaultLayout>
              <Questions />
            </DefaultLayout>
          </RequireAuthWrapper>
        }
      />

      <Route
        path='/questions/details'
        element={
          <RequireAuthWrapper>
            <DefaultLayout>
              <QuestionDetail />
            </DefaultLayout>
          </RequireAuthWrapper>
        }
      />

      <Route
        path='/settings'
        element={
          <RequireAuthWrapper>
            <DefaultLayout>
              <h1>Configurações</h1>
            </DefaultLayout>
          </RequireAuthWrapper>
        }
      />

      <Route
        path='/users'
        element={
          <RequireAuthWrapper>
            <DefaultLayout>
              <Users />
            </DefaultLayout>
          </RequireAuthWrapper>
        }
      />

      <Route
        path='/clinics'
        element={
          <RequireAuthWrapper>
            <DefaultLayout>
              <Clinics />
            </DefaultLayout>
          </RequireAuthWrapper>
        }
      />

      <Route
        path='/patients'
        element={
          <RequireAuthWrapper>
            <DefaultLayout>
              <Patients />
            </DefaultLayout>
          </RequireAuthWrapper>
        }
      />

      <Route
        path='/informants'
        element={
          <RequireAuthWrapper>
            <DefaultLayout>
              <Informants />
            </DefaultLayout>
          </RequireAuthWrapper>
        }
      />

      <Route
        path='/users/details'
        element={
          <RequireAuthWrapper>
            <DefaultLayout>
              <UsersDetail />
            </DefaultLayout>
          </RequireAuthWrapper>
        }
      />

      <Route
        path='/questions-blocks'
        element={
          <RequireAuthWrapper>
            <DefaultLayout>
              <QuestionsBlocks />
            </DefaultLayout>
          </RequireAuthWrapper>
        }
      />

      <Route
        path='/questions-blocks/details'
        element={
          <RequireAuthWrapper>
            <DefaultLayout>
              <QuestionsBlocksDetails />
            </DefaultLayout>
          </RequireAuthWrapper>
        }
      />

      <Route
        path='/tags'
        element={
          <RequireAuthWrapper>
            <DefaultLayout>
              <Tags />
            </DefaultLayout>
          </RequireAuthWrapper>
        }
      />

      <Route
        path='/tags/details'
        element={
          <RequireAuthWrapper>
            <DefaultLayout>
              <TagsDetails />
            </DefaultLayout>
          </RequireAuthWrapper>
        }
      />
    </Routes>
  )
}

export default App
