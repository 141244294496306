import { Box } from '@mui/material'
import { Button, Table } from 'components'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getQuestionsBlocks } from 'services'
import { Container } from 'styles/container'
import { QuestionsBlocksProps } from 'types/questions-blocks-types'

function QuestionsBlocks() {
  const [data, setData] = useState<QuestionsBlocksProps[]>()
  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()

  const handleRowClick = (row: any) => {
    navigate('/questions-blocks/details', {
      state: {
        id: row.id,
      },
    })
  }

  useEffect(() => {
    let shouldUpdateState = true

    const loadData = async () => {
      try {
        setIsLoading(true)
        const reply = await getQuestionsBlocks()
        if (shouldUpdateState) {
          setData(reply)
        }
      } catch (error: any) {
      } finally {
        setIsLoading(false)
      }
    }

    loadData()

    return () => {
      shouldUpdateState = false
    }
  }, [])

  return (
    <Container style={{ paddingTop: 0 }}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <h1>Blocos</h1>
        <Button
          type='button'
          color='secondary'
          onClick={() => navigate('/questions-blocks/details')}
          sx={{ width: '200px' }}
        >
          Adicionar
        </Button>
      </Box>
      <Table
        rows={data || []}
        columns={[
          { field: 'id', headerName: 'Id' },
          { field: 'name', headerName: 'Nome', width: 250 },
        ]}
        fileName='blocos'
        isLoading={isLoading}
        onRowClick={handleRowClick}
      />
    </Container>
  )
}

export default QuestionsBlocks
