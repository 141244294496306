/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { People, QuestionAnswer, Settings } from '@mui/icons-material'
import StyleIcon from '@mui/icons-material/Style'
import GridViewIcon from '@mui/icons-material/GridView'
import { SvgIcon } from '@mui/material'

import {
  CenterIcon,
  Container,
  HiddenSidebar,
  MiddleSidebar,
  SidebarList,
  SidebarListItem,
  SidebarStyled,
} from './sidebar-styles'
import './styles.css'

export interface ColorProps {
  fill: string
}

interface SidebarProps {
  content: ReactNode
}

function Sidebar({ content }: SidebarProps) {
  const [currentNav, setCurrentNav] = useState('')

  const location = useLocation()

  const sidebar = [
    {
      name: 'Perguntas',
      icon: QuestionAnswer,
      href: '/questions',
    },
    {
      name: 'Blocos',
      icon: GridViewIcon,
      href: '/questions-blocks',
    },
    {
      name: 'Domínios',
      icon: StyleIcon,
      href: '/tags',
    },
    {
      name: 'Usuários',
      icon: People,
      href: '/users',
    },
    {
      name: 'Clínicos',
      icon: People,
      href: '/clinics',
    },
    {
      name: 'Pacientes',
      icon: People,
      href: '/patients',
    },
    {
      name: 'Convidados',
      icon: People,
      href: '/informants',
    },
    {
      name: 'Configurações',
      icon: Settings,
      href: '/settings',
    },
  ]

  useEffect(() => {
    setCurrentNav(
      sidebar.find(
        (item) => location.pathname.split('/')[1] === item.href.split('/')[1]
      )?.name || ''
    )
  }, [location])

  return (
    <Container>
      <SidebarStyled>
        <MiddleSidebar style={{ backgroundColor: '#F2F2F280' }}>
          <SidebarList>
            {sidebar.map((item) => (
              <SidebarListItem
                key={item.name}
                isActive={item.name === currentNav}
              >
                <NavLink
                  to={item.href}
                  className={({ isActive }) =>
                    `sidebar-link ${isActive ? 'active-menu' : ''}`
                  }
                >
                  <CenterIcon isActive={item?.name === currentNav}>
                    <SvgIcon component={item.icon} />
                  </CenterIcon>
                  <HiddenSidebar isActive={item?.name === currentNav}>
                    {item.name}
                  </HiddenSidebar>
                </NavLink>
              </SidebarListItem>
            ))}
          </SidebarList>
        </MiddleSidebar>
      </SidebarStyled>
      {content}
    </Container>
  )
}

export default Sidebar
