import { styled } from '@mui/material'

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 24px;
  padding: 24px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
`
