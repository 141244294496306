import { useEffect, useState } from 'react'

import { Table } from 'components'
import { getClinicsWithCount } from 'services'
import { Container } from 'styles/container'
import { ClinicWithCount } from 'types/clinics-types'

export const Clinics = () => {
  const [data, setData] = useState<ClinicWithCount[]>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let shouldUpdateState = true

    const loadData = async () => {
      try {
        setIsLoading(true)
        const reply = await getClinicsWithCount()

        if (shouldUpdateState) {
          setData(reply)
        }
      } catch (error: any) {
      } finally {
        setIsLoading(false)
      }
    }

    loadData()

    return () => {
      shouldUpdateState = false
    }
  }, [])

  return (
    <Container>
      <h1 style={{ marginTop: 0 }}>Clínicos</h1>
      <Table
        rows={
          data?.map((item) => {
            return {
              ...item,
              firstName: item.personalInfo.firstName,
              lastName: item.personalInfo.lastName,
              email: item.user.email,
              completedFormRequestsCount: Number(
                item.completedFormRequestsCount
              ),
              formRequestsCount: Number(item.formRequestsCount),
              invitesCount: Number(item.invitesCount),
              patientsCount: Number(item.patientsCount),
            }
          }) || []
        }
        columns={[
          { field: 'id', headerName: 'Id' },
          { field: 'email', headerName: 'Email', width: 300 },
          { field: 'firstName', headerName: 'Primeiro nome', width: 150 },
          { field: 'lastName', headerName: 'Último nome' },
          {
            field: 'invitesCount',
            headerName: 'Pacientes convidados',
            width: 200,
          },
          {
            field: 'patientsCount',
            headerName: 'Pacientes registrados',
            width: 200,
          },
          {
            field: 'formRequestsCount',
            headerName: 'Questionários enviados',
            width: 200,
          },
          {
            field: 'completedFormRequestsCount',
            headerName: 'Questionários respondidos',
            width: 200,
          },
        ]}
        isLoading={isLoading}
      />
    </Container>
  )
}
