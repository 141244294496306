import client from 'services/client'
import { PatientProps } from 'types/patients-types'

export const getPatients = async () => {
  const { data } = await client.get('patients')
  return data
}

export const getPatientsWithCount = async () => {
  const { data } = await client.get('patients?withCount=1')
  return data
}

export const fetchPatient = async (id: string) => {
  const { data }: any = await client.get<PatientProps[]>(`patients/${id}`)
  return data
}

export const getPatientTags = async (id: string) => {
  const { data }: any = await client.get(`patient-tags?patientId=${id}`)
  return data
}

export const deletePatientTag = async (id: string) => {
  await client.delete(`patient-tags/${id}`)
}

export const createPatientTag = async (patientId: string, text: string) => {
  const body = { patientId, text }
  await client.post(`patient-tags`, body)
}

export const getPatientLogs = async (id: string) => {
  const { data }: any = await client.get(`patient-logs?patientId=${id}`)
  return data
}

export const updateMyPatientInfos = async (body: any) => {
  const { data }: any = await client.patch('patients', body)
  return data
}
