import { useForm } from 'react-hook-form'
import { TagProps, TagUpdateData } from 'types/tags-types'

function useTagDetailForm(data?: TagProps) {
  const { control, handleSubmit, watch } = useForm<TagUpdateData>({
    mode: 'onBlur',
    values: {
      name: data?.name || '',
      observation: data?.observation || '',
    },
  })

  return { control, handleSubmit, watch }
}

export default useTagDetailForm
