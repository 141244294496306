import { Box, CircularProgress } from '@mui/material'

interface LoadingProps {
  isLoading?: boolean
}

function Loading({ isLoading }: LoadingProps) {
  return (
    <Box position='absolute' top='50%' bottom='50%' left='50%' right='50%'>
      {!!isLoading && <CircularProgress size={32} />}
    </Box>
  )
}

export default Loading
