import { styled } from '@mui/material/styles'

interface ContainerProps {
  backgroundColor?: string
}

export const Container = styled('header')<ContainerProps>`
  height: 100px;
  display: flex;
  align-items: center;
  padding: 0 3rem;

  background-color: ${({ backgroundColor = '#9d9cb5' }) => backgroundColor};
`

export const EnvironmentText = styled('h2')`
  color: white;
  margin-left: 24px;
  align-self: flex-end;
  text-transform: capitalize;
`
