import client from './client'

export const getInformants = async () => {
  const { data } = await client.get('informants')
  return data
}

export const getInformantsWithCount = async () => {
  const { data } = await client.get('informants?withCount=1')
  return data
}
