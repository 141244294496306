import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'

import { Table } from 'components'
import { getUsers } from 'services'
import { UserProps } from '../../types/users-types'
import { Container } from 'styles/container'

function Users() {
  const [data, setData] = useState<UserProps[]>()
  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()

  const handleRowClick = (row: any) => {
    navigate('/users/details', {
      state: { id: row.id },
    })
  }

  useEffect(() => {
    let shouldUpdateState = true

    const loadData = async () => {
      try {
        setIsLoading(true)
        const reply = await getUsers()
        if (shouldUpdateState) {
          setData(reply)
        }
      } catch (error: any) {
      } finally {
        setIsLoading(false)
      }
    }

    loadData()

    return () => {
      shouldUpdateState = false
    }
  }, [])

  return (
    <Container>
      <h1 style={{ marginTop: 0 }}>Usuários</h1>
      <Table
        rows={
          data?.map((item) => {
            return {
              ...item,
              roles: item.roles.toString(),
              communication: item.communication.toString(),
              createdAt: Number(new Date(item.createdAt).setMilliseconds(0)),
            }
          }) || []
        }
        columns={[
          { field: 'id', headerName: 'Id' },
          { field: 'email', headerName: 'Email', width: 250 },
          { field: 'roles', headerName: 'Cargo' },
          { field: 'communication', headerName: 'Comunicação', width: 200 },
          {
            field: 'createdAt',
            headerName: 'Data de cadastro',
            width: 200,
            renderCell: (props) =>
              format(new Date(props.value), 'dd/MM/yyyy HH:mm'),
          },
        ]}
        fileName='usuarios'
        isLoading={isLoading}
        onRowClick={handleRowClick}
      />
    </Container>
  )
}

export default Users
