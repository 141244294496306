import { ComponentProps, FC } from 'react'
import { ThemeProvider, RouterProvider, AuthProvider } from 'providers'

const combineComponents = (components: FC[]): FC =>
  components.reduce(
    (AccumulatedComponents, CurrentComponent) =>
      ({ children }: ComponentProps<FC>): JSX.Element =>
        (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        ),
    ({ children }: ComponentProps<FC>) => <>{children}</>
  )

const AppProvider = combineComponents([
  RouterProvider,
  ThemeProvider,
  AuthProvider,
])

export default AppProvider
