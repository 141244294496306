import { styled } from '@mui/material/styles'

export const GradientLink = styled('a')`
  background: -webkit-linear-gradient(left, #00b3a6, #f36f60, #f36f60);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 0.4s;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  font-weight: 700;
  &:link,
  &:visited,
  &:active,
  &:focus,
  &:default {
    background: -webkit-linear-gradient(left, #00b3a6, #f36f60, #f36f60);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 0.4s;

    &:hover {
      opacity: 0.6;
    }
  }
`
